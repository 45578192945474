import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import ChevronIcon from '../icons/chevron-accordion'

const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  background-color: black;

  * {
    box-sizing: border-box;
  }
`

const AccordionButton = styled.button`
  cursor: pointer;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  border: none;
  outline: none;
  transition: background-color 200ms ease;
  background-color: ${props => props.theme.colors.white};
  text-align: left;

  ${props => props.active && `background-color: black;`}

  :hover {
    background-color: ${props =>
      !props.active && props.theme.core.background.secondary};
  }

  .section-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`

const AccordionTitle = styled.p`
  font-family: ${props => props.theme.typography.fontFamilyGTMono};
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: ${props => props.theme.core.content.inverseTertiary};
`

const AccordionHeader = styled.h3`
  font-family: ${props => props.theme.typography.fontFamilyInter};
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin: 0.75rem 0rem;
  color: ${props =>
    props.active
      ? props.theme.core.content.inversePrimary
      : props.theme.core.content.primary};
`

const AccordionIcon = styled.div`
  margin-left: auto;
  transition: transform 200ms ease;
  ${props => props.active && `transform: rotate(180deg);`}
`

const AccordionContent = styled.div`
  background-color: #fff;
  overflow: hidden;
  transition: all 200ms ease;
  box-sizing: border-box;
  ${props => props.active && `background-color: black; padding: 1.5rem; `}
  ${props => !props.active && 'visibility: hidden;'}
`

const Accordion = ({
  title,
  header,
  content,
  handleChange,
  active: _active,
}) => {
  const [active, setActive] = useState(false)
  const [height, setHeight] = useState('0rem')
  const contentRef = useRef(null)

  const toggleAccordion = () => {
    if (handleChange && typeof handleChange === 'function') {
      handleChange()
    } else {
      setActive(!active)
    }
  }

  useEffect(() => {
    setActive(_active)
  }, [_active])

  useEffect(() => {
    setHeight(active ? `${contentRef.current.scrollHeight + 2}px` : '0rem')
  }, [active])

  return (
    <AccordionSection>
      <AccordionButton onClick={toggleAccordion} active={active}>
        <div className="section-title">
          <AccordionTitle>{title}</AccordionTitle>
          <AccordionIcon active={active}>
            <ChevronIcon stroke={active ? '#fff' : '#000'} />
          </AccordionIcon>
        </div>
        <AccordionHeader active={active}>{header}</AccordionHeader>
      </AccordionButton>
      <AccordionContent
        active={active}
        ref={contentRef}
        style={{ maxHeight: `${height}` }}
      >
        {content}
      </AccordionContent>
    </AccordionSection>
  )
}

export default Accordion
