import React, { useState } from 'react'
import styled, { css, keyframes, useTheme } from 'styled-components'
import Box from './box'
import Typography from './typography'

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(5%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  .show-on-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0rem;

    @media (min-width: 500px) {
      margin-bottom: 5rem;
    }
  }

  .selected-container {
    position: relative;
    display: flex;
    width: 100%;
    height: 500px;
    align-items: center;
    justify-content: center;

    svg,
    img,
    div {
      animation: ${fadeIn} 200ms ease-in-out;
    }
  }

  @media ${props => props.theme.device.tablet} {
    .selected-container {
      height: auto;
    }
  }

  @media ${props => props.theme.device.laptop} {
    flex-direction: row;
    align-items: flex-start;

    .selected-container {
      position: sticky;
      top: 64px;
      width: 80%;
      height: auto;
    }

    .show-on-mobile {
      display: none;
    }
  }
`

const Cards = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 1;
  max-width: 100vw;
  min-width: 20rem;
  margin-right: 40px;
  margin-top: 64px;
  @media ${props => props.theme.device.laptop} {
    flex-direction: column;
    width: auto;
    justify-content: flex-start;
  }
`

const Card = styled.button`
  display: flex;
  flex-direction: column;
  background: none;
  border: none;
  outline: none;
  transition: 100ms;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
  text-align: left;
  margin-bottom: 0.5rem;
  width: 100%;
  margin: 0 1rem;

  :hover {
    background: ${props =>
      !props.selected && props.theme.core.background.secondary};
  }

  ${props =>
    props.selected &&
    css`
      background: ${props => props.theme.core.background.inversePrimary};
    `}

  .hide-on-mobile {
    display: none;
  }

  @media ${props => props.theme.device.laptop} {
    margin: 0rem;
    margin-bottom: 1rem;
    padding: 1rem 1.5rem;

    .hide-on-mobile {
      display: block;
    }
  }
`

const TabPickerInverted = ({ options }) => {
  const theme = useTheme()
  // Index of the selected option
  // Defaults to the first option
  const [selected, setSelected] = useState(0)
  const SelectedComponent = options[selected].component

  return (
    <Container>
      <Box className="show-on-mobile">
        <Typography
          component="h4"
          style={{
            margin: '.75rem 0',
            fontFamily: theme.typography.fontFamilyInter,
          }}
          textType="paragraph-large"
          fontWeight={500}
        >
          {options[selected].title}
        </Typography>
        <Typography
          component="p"
          textType="paragraph-medium"
          colorType="core.content.tertiary"
          textAlign="center"
        >
          {options[selected].description}
        </Typography>
      </Box>
      <Cards>
        {options.map((option, index) => {
          const isSelected = index === selected
          return (
            <Card
              key={index}
              onClick={() => setSelected(index)}
              selected={isSelected}
            >
              <Box>{option.icon}</Box>
              <article>
                <Typography
                  component="h4"
                  textType="paragraph-large"
                  fontWeight={500}
                  className="hide-on-mobile"
                  style={{
                    margin: '.75rem 0',
                    color: isSelected ? '#FFF' : '#000',
                    fontFamily: theme.typography.fontFamilyInter,
                  }}
                >
                  {option.title}
                </Typography>
                {isSelected && (
                  <Typography
                    component="p"
                    textType="paragraph-small"
                    className="hide-on-mobile"
                    style={{ color: isSelected ? '#CBCBCB' : '#757575' }}
                  >
                    {option.description}
                  </Typography>
                )}
              </article>
            </Card>
          )
        })}
      </Cards>
      <Box className="selected-container">{SelectedComponent}</Box>
    </Container>
  )
}

export default TabPickerInverted
