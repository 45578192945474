import React from 'react'
import styled from 'styled-components'
import { Section, InnerSection } from '../pages'
import { AnimatePresence, motion } from 'framer-motion'

const Container = styled.div`
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  width: 670px;
  div:not(last-child), section:not(last-child) {
    border-bottom: 1px solid #EEEEEE;
  }
  @media ${props => props.theme.device.tabletLToLower} {
    width: 100%;
  }
`

const CollapsibleButton = styled.div`
  cursor: pointer;
  padding: 11px 16px;
  font-size: 20px;
  line-height: 26px;
  color: #111111;
  display: flex;
  justify-content: space-between;
`

const FaqSection = () => {
  const [collapsedIndexes, setCollapsedIndexes] = React.useState([])

  const faqs = [
    // {
    //   question: `Is there a free plan?`,
    //   content: `Yes, Pulse has a free plan. Pulse is free up to 20 people and with some basic limitations like limited search functionalities (access to the most recent videos and posts), up to 500GB of video content, and public-only streams. It’s perfect for small distributed startups that are looking to communicate more effectively.`
    // },
    // {
    //   question: `Do you offer a free trial of premium functionality?`,
    //   content: (
    //     <>
    //       In addition to our free plan, you can try the Pro features for 14 days. The 14 days Pro trial starts immediately after you create a new workspace. No credit card is required.

    //       We recommend you invite your team and see how everything works collaboratively. If you need fore more days, just get in touch with us at <a href='mailto:support@pulse.so' className='text-black underline'>support@pulse.so</a>.
    //     </>
    //   )
    // },
    // {
    //   question: `What happens when my trial ends?`,
    //   content: `Once your trial ends, your workspace will be downgraded to our free plan. If you’re wondering how much time you have left on your trial, there’s a handy indicator in the left-hand navigation bar.`
    // },
    {
      question: `Do you offer a free trial of premium functionality?`,
      content: (
        <>
          You can try Pulse for 14 days. The 14 days trial starts immediately after you create a new workspace. No credit card is required.

          We recommend you invite your team and see how everything works collaboratively. If you need fore more days, just get in touch with us at <a href='mailto:support@pulse.so' className='text-black underline'>support@pulse.so</a>.
        </>
      )
    },
    {
      question: `What happens when my trial ends?`,
      content: `Once your trial ends, your workspace will be locked. If you’re wondering how much time you have left on your trial, there’s a handy indicator in the left-hand navigation bar.`
    },
    {
      question: `How secure is Pulse?`,
      content:
        `We take data security very seriously at Pulse.
        
        Our infrastructure is hosted on Amazon Web Services and benefits from industry-leading security and data policies. All data is encrypted both in transit and at rest. Each workspace has a dedicated AWS account that stores and manages all the assets securely with passwords, SSO certificates, and any other authentication data encrypted end-to-end.`
    },
    {
      question: `Is Pulse GDPR compliant?`,
      content: <>
        Yes.

        You can delete your workspace and your data whenever you want, just drop us a line to <a href='mailto:support@pulse.so' className='text-black underline'>support@pulse.so</a>.
      </>
    },
    {
      question: `What forms of payment do you support?`,
      content: `Payments are processed by the Stripe gateway.

We handle payments via Credit card, ACH, or wire transfer.
      
You can contact us to learn more.`
    },
    {
      question: `Do you offer a discount for education and non-profit organizations?`,
      content: `We support teams and organizations who work to make the world a better place. We offer discounted rates for non-profit organizations, as well as staff and faculty members of educational institutions.`
    },
    {
      question: `Does it have a mobile app?`,
      content: `Our entire app is fully responsive and works on any mobile device. We’ll soon have iOS and Android native apps.`
    },
    {
      question: `More questions?`,
      content: (
        <>
          We’d love to meet you. <a href='mailto:support@pulse.so' className='text-black underline'>Contact us</a>.
        </>
      )
    }
  ]

  return (
    <Section>
      <InnerSection className='grid place-content-center py-96 tabletLToLower:py-64'>
        <div className='text-5xl leading-52 text-center tabletLToLower:text-4xl tabletLToLower:leading-[42px]'>
          FAQ
        </div>
        <Container className='mt-32'>
          {faqs.map((faq, index) => (
            <>
              <CollapsibleButton
                key={index}
                onClick={() => {
                  if (collapsedIndexes.includes(index)) {
                    setCollapsedIndexes(collapsedIndexes.filter(i => i !== index))
                  } else {
                    setCollapsedIndexes([...collapsedIndexes, index])
                  }
                }}
              >
                <span>
                  {faq.question}
                </span>
                <span className='grid place-content-center'>
                  {collapsedIndexes.includes(index) ? (
                    <svg width="12" height="3" viewBox="0 0 12 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.36 0.76H11.22V2.48H0.36V0.76Z" fill="#111111" />
                    </svg>
                  ) : (
                    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.7 11H4.88V6.48H0.36V4.76H4.88V0.36H6.7V4.76H11.22V6.48H6.7V11Z" fill="#111111" />
                    </svg>
                  )}
                </span>
              </CollapsibleButton>
              <AnimatePresence initial={false}>
                {collapsedIndexes.includes(index) && (
                  <motion.section
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "auto" },
                      collapsed: { opacity: 0, height: 0 }
                    }}
                    transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
                    style={{
                      padding: '8px 16px',
                      fontSize: '16px',
                      lineHeight: '20px',
                      whiteSpace: 'break-spaces'
                    }}
                  >
                    {faq.content}
                  </motion.section>
                )}
              </AnimatePresence>
            </>
          ))}
        </Container>
      </InnerSection>
    </Section>
  )
}

export default FaqSection
