import React from 'react'
import styled from 'styled-components'
import Image from './image'

const Container = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 2.5rem;
  }
`

const CompanyIcons = () => (
  <Container>
    <Image
      src="companies/cloud-academy.png"
      alt="Cloud Academy"
      style={{ height: '1.25rem', width: 'auto' }}
      nativeImage
    />
    <Image
      src="companies/compass.png"
      alt="Compass"
      nativeImage
      style={{ height: '0.85rem', width: 'auto' }}
    />
    <Image
      src="companies/hubspot.png"
      alt="Hubspot"
      nativeImage
      style={{ height: '1.0525rem', width: 'auto' }}
    />
    <Image
      src="companies/people-ai.png"
      alt="People AI"
      nativeImage
      style={{ height: '1.0569rem', width: 'auto' }}
    />
    <Image
      src="companies/bytedance.png"
      alt="ByteDance"
      nativeImage
      style={{ height: '1.1013rem', width: 'auto' }}
    />
  </Container>
)

export default CompanyIcons
