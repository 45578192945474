import React from 'react'
import styled, { useTheme } from 'styled-components'
import { navigate } from 'gatsby'

import Typography from './typography'
import siteConfig from '../../data/SiteConfig'

const Card = styled.li`
  background: ${props => props.theme.core.background.primary};
  border-radius: 0.625rem;
  width: 15.1875rem;
  height: 12.75rem;
  box-sizing: border-box;
  box-shadow: 0rem 0.25rem 1rem rgba(0, 0, 0, 0.08);
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 200ms ease-in-out;

  :hover,
  :focus {
    transform: translateY(-0.125rem);
  }
`

const Top = styled.div`
  flex: 36;
  padding: 1.5rem 1.5rem 0.625rem 1.5rem;
`

const Bottom = styled.div`
  flex: 100;
  background: rgba(246, 246, 246, 0.5);
  padding: 0.875rem 1.5rem 1.5rem 1.5rem;
`

const IntegrationCard = props => {
  const theme = useTheme()
  const {
    integration: {
      frontmatter,
      fields: { slug },
    },
    style,
  } = props
  const { icon, label, description } = frontmatter || {}

  function navigateToIntegration() {
    navigate(siteConfig.integrationsPrefix + '/' + slug)
  }

  return (
    <Card
      onClick={navigateToIntegration}
      onKeyPress={navigateToIntegration}
      tabIndex={0}
      role="button"
      style={style}
    >
      <Top>
        <img src={icon && icon.publicURL} alt={label} />
      </Top>
      <Bottom>
        <Typography
          textType="label-medium"
          fontWeight="500"
          component="h4"
          style={{
            margin: 0,
            marginBottom: '0.75rem',
            fontFamily: theme.typography.fontFamilyInter,
          }}
        >
          {label}
        </Typography>
        <Typography
          textType="paragraph-small"
          component="p"
          style={{ margin: 0, color: theme.core.content.secondary }}
        >
          {description}
        </Typography>
      </Bottom>
    </Card>
  )
}
export default IntegrationCard
