import React, { useState } from 'react'

const AccordionSingleSelect = ({ children }) => {
  const [expandedId, setExpandedId] = useState(null)

  const handleAccordionChange = id => {
    setExpandedId(id === expandedId ? null : id)
  }

  return <>{children({ handleAccordionChange, expandedId })}</>
}

export default AccordionSingleSelect
